export default {
	"pages": {
		"root": {
			"id": "root",
			"pageUrl": "root",
			"name": "root",
			"children": [
				"6552e86a867c86001f17b1fa",
				"6552e86a867c86001f17b1fd"
			]
		},
		"6552e86a867c86001f17b1fa": {
			"id": "6552e86a867c86001f17b1fa",
			"name": "404",
			"pageUrl": "404"
		},
		"6552e86a867c86001f17b1fd": {
			"id": "6552e86a867c86001f17b1fd",
			"name": "index",
			"pageUrl": "index"
		}
	},
	"mode": "production",
	"projectType": "create-react-app",
	"site": {
		"styles": {},
		"seo": {
			"favicon_32px": "https://uploads.quarkly.io/6552e86a867c86001f17b1f5/images/cctv-svgrepo-com.svg?v=2023-11-15T07:38:47.291Z",
			"title": "ABCam Service",
			"description": "Сервис видеонаблюдения для ваших клиентов",
			"robotsFileType": "autogenerated",
			"sitemapFileType": "autogenerated",
			"customSitemapDomain": "https://abcam.kz/",
			"favicon_apple_152px": "https://uploads.quarkly.io/6552e86a867c86001f17b1f5/images/cctv-svgrepo-com.svg?v=2023-11-15T07:38:47.291Z",
			"favicon_win10_270px": "https://uploads.quarkly.io/6552e86a867c86001f17b1f5/images/cctv-svgrepo-com.svg?v=2023-11-15T07:38:47.291Z",
			"background_win10": "#272727",
			"customSitemapXml": "<?xml version=\"1.0\" encoding=\"UTF-8\"?>\n<urlset\n      xmlns=\"http://www.sitemaps.org/schemas/sitemap/0.9\"\n      xmlns:xsi=\"http://www.w3.org/2001/XMLSchema-instance\"\n      xsi:schemaLocation=\"http://www.sitemaps.org/schemas/sitemap/0.9\n            http://www.sitemaps.org/schemas/sitemap/0.9/sitemap.xsd\">\n<url>\n  <loc>https://www.abcam.kz/</loc>\n  <lastmod>2023-11-15T07:39:39+00:00</lastmod>\n</url>\n</urlset>"
		}
	}
}