import React from "react";
import theme from "theme";
import { Theme, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"404"} />
		<Helmet>
			<title>
				ABCam Service
			</title>
			<meta name={"description"} content={"Сервис видеонаблюдения для ваших клиентов"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6552e86a867c86001f17b1f5/images/cctv-svgrepo-com.svg?v=2023-11-15T07:38:47.291Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6552e86a867c86001f17b1f5/images/cctv-svgrepo-com.svg?v=2023-11-15T07:38:47.291Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6552e86a867c86001f17b1f5/images/cctv-svgrepo-com.svg?v=2023-11-15T07:38:47.291Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6552e86a867c86001f17b1f5/images/cctv-svgrepo-com.svg?v=2023-11-15T07:38:47.291Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6552e86a867c86001f17b1f5/images/cctv-svgrepo-com.svg?v=2023-11-15T07:38:47.291Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6552e86a867c86001f17b1f5/images/cctv-svgrepo-com.svg?v=2023-11-15T07:38:47.291Z"} />
			<meta name={"msapplication-TileColor"} content={"#272727"} />
		</Helmet>
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6552e86a867c86001f17b1f3"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});